// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDUQ8P0HVfLuxPc-VgRnb8ukhK0Qbl-00g",
  authDomain: "farmanothman-1f6b7.firebaseapp.com",
  projectId: "farmanothman-1f6b7",
  storageBucket: "farmanothman-1f6b7.appspot.com",
  messagingSenderId: "114983491353",
  appId: "1:114983491353:web:d7757c2997e7015f4b5788"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);