
function App() {
  return (

<body>
   <div class="preloader">
      <svg viewBox="0 0 1000 1000" preserveAspectRatio="none">
         <path id="preloaderSvg" d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"></path>
      </svg>

      <div class="preloader-heading">
         <div class="load-text">
            <span>L</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
         </div>
      </div>
   </div>

   <div class="progress-wrap" id="scrollUp">
      <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
         <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
   </div>
 
   <header class="tj-header-area header-absolute">
      <div class="container">
         <div class="row">
            <div class="col-12 d-flex flex-wrap align-items-center">
               

               <div class="header-info-list d-none d-md-inline-block">
                  <ul class="ul-reset">
                     <li><a  > <span className="">P</span>ortfolio</a></li>
                  </ul>
               </div>
               <div class="header-menu">
                  <nav>
                     <ul>
                        <li><a href="#services-section">Services</a></li>
                        <li><a href="#works-section">Works</a></li>
                        <li><a href="#resume-section">Resume</a></li>
                        <li><a href="#skills-section">Skills</a></li>
                        <li><a href="#contact-section">Contact</a></li>
                     </ul>
                  </nav>
               </div>
               <div class="header-button">
                  <a href="#" class="btn tj-btn-primary  text-white">Hire me!</a>
               </div>
               <div class="menu-bar d-lg-none">
                  <button>
                     <span></span>
                     <span></span>
                     <span></span>
                     <span></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </header>
   <header class="tj-header-area header-2 header-sticky sticky-out">
      <div class="container">
         <div class="row">
            <div class="col-12 d-flex flex-wrap align-items-center">
           

               <div class="header-info-list d-none d-md-inline-block">
                  <ul class="ul-reset">
                  <li><a  > <span className="">P</span>ortfolio</a></li>
                  </ul>
               </div>

               <div class="header-menu">
                  <nav>
                     <ul>
                        <li><a href="#services-section">Services</a></li>
                        <li><a href="#works-section">Works</a></li>
                        <li><a href="#resume-section">Resume</a></li>
                        <li><a href="#skills-section">Skills</a></li>
                        <li><a href="#contact-section">Contact</a></li>
                     </ul>
                  </nav>
               </div>

               <div class="header-button">
                  <a href="#" class="btn tj-btn-primary">Hire me!</a>
               </div>

               <div class="menu-bar d-lg-none">
                  <button>
                     <span></span>
                     <span></span>
                     <span></span>
                     <span></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </header>

   <main class="site-content" id="content">

      <section class="hero-section d-flex align-items-center" id="intro">
         <div class="intro_text">
            <svg viewBox="0 0 1320 300">
               <text x="50%" Y="50%" text-anchor="middle">~</text>
            </svg>
         </div>
         <div class="container">
            <div class="row align-items-center">
               <div class="col-md-6">
                  <div class="hero-content-box">
                     <span class="hero-sub-title">I am Farman Othman</span>
                     <h1 class="hero-title">Web Developer +<br />Graphic Designer</h1>

                     <div class="hero-image-box d-md-none text-center">
                        <img src="assets/img/hero/me.png" alt="" />
                     </div>

                     <p class="lead">
                     I'm a professional graphic designer and web developer, specializing in creating visually appealing designs and functional, user-friendly websites.
                     </p>
                     <div class="button-box d-flex flex-wrap align-items-center">
                        {/* <a href="#" class="btn tj-btn-secondary">Download CV <i class="flaticon-download"></i></a> */}
                        <ul class="ul-reset social-icons">
                          
                           
                           <li>
                              <a href="https://www.linkedin.com/in/farman-othman-042046176/"><i class="fa-brands fa-linkedin-in"></i></a>
                           </li>
                           <li>
                              <a href="https://www.behance.net/farman_othman"><i class="fa-brands fa-behance"></i></a>
                           </li>
                          
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-md-6 d-none d-md-block">
                  <div class="hero-image-box text-center">
                     <img src="assets/img/hero/me.png" alt="" />
                  </div>
               </div>
            </div>
            <div class="funfact-area">
               <div class="row">
                  <div class="col-6 col-lg-3">
                     <div class="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                        <div class="number"><span class="odometer" data-count="5">5</span></div>
                        <div class="text">Years of <br />Experience</div>
                     </div>
                  </div>
                  <div class="col-6 col-lg-3">
                     <div class="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                        <div class="number"><span class="odometer" data-count="25">25</span>+</div>
                        <div class="text">Project <br />Completed</div>
                     </div>
                  </div>
                  <div class="col-6 col-lg-3">
                     <div class="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                        <div class="number"><span class="odometer" data-count="100">100</span></div>
                        <div class="text">Happy <br />Clients</div>
                     </div>
                  </div>
                  <div class="col-6 col-lg-3">
                     <div class="funfact-item d-flex flex-column flex-sm-row flex-wrap align-items-center">
                        <div class="number"><span class="odometer" data-count="5">5</span></div>
                        <div class="text">Years of <br />Experience</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   
      <section class="services-section" id="services-section">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="section-header text-center">
                     <h2 class="section-title wow fadeInUp" data-wow-delay=".3s">My Services</h2>
                    
                  </div>
               </div>
            </div>

            <div class="row">
               <div class="col-md-12">
                  <div class="services-widget position-relative">
                     <div class="service-item current d-flex flex-wrap align-items-center wow fadeInUp"
                        data-wow-delay=".5s">
                        <div class="left-box d-flex flex-wrap align-items-center">
                           <span class="number">01</span>
                           <h3 class="service-title">Branding Design</h3>
                        </div>
                        <div class="right-box">
                           <p>
                           I can provide professional design services to create visually appealing and effective materials that elevate your brand and attract customers.
                           </p>
                        </div>
                        {/* <i class="flaticon-up-right-arrow"></i>
                        <button data-mfp-src="#service-wrapper" class="service-link modal-popup"></button> */}
                     </div>
                     <div class="service-item d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".6s">
                        <div class="left-box d-flex flex-wrap align-items-center">
                           <span class="number">02</span>
                           <h3 class="service-title">Web Design</h3>
                        </div>
                        <div class="right-box">
                           <p>
                           I create professional, high-quality website designs for companies, ensuring an engaging user experience and a strong online presence.
                           </p>
                        </div>
                        {/* <i class="flaticon-up-right-arrow"></i>
                        <button data-mfp-src="#service-wrapper" class="service-link modal-popup"></button> */}
                     </div>
                     <div class="service-item d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".7s">
                        <div class="left-box d-flex flex-wrap align-items-center">
                           <span class="number">03</span>
                           <h3 class="service-title">UI/UX Design</h3>
                        </div>
                        <div class="right-box">
                           <p>
                           I create professional UI/UX designs that ensure an intuitive, engaging, and seamless user experience, enhancing customer satisfaction and driving business growth for your company.
                           </p>
                        </div>
                        {/* <i class="flaticon-up-right-arrow"></i>
                        <button data-mfp-src="#service-wrapper" class="service-link modal-popup"></button> */}
                     </div>
                     <div class="service-item d-flex flex-wrap align-items-center wow fadeInUp" data-wow-delay=".8s">
                        <div class="left-box d-flex flex-wrap align-items-center">
                           <span class="number">04</span>
                           <h3 class="service-title">Graphics Design</h3>
                        </div>
                        <div class="right-box">
                           <p>
                           I can create the best professional graphic designs, including banners, social media graphics, logos, brochures, and flex designs, to effectively promote your brand and captivate your target audience.
                           </p>
                        </div>
                        {/* <i class="flaticon-up-right-arrow"></i>
                        <button data-mfp-src="#service-wrapper" class="service-link modal-popup"></button> */}
                     </div>
                     <div class="active-bg wow fadeInUp" data-wow-delay=".5s"></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
 
     
  
      <section class="portfolio-section" id="works-section">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="section-header text-center">
                     <h2 class="section-title wow fadeInUp" data-wow-delay=".3s">My Recent Works</h2>
                     <p class="wow fadeInUp" data-wow-delay=".4s">
                        We put your ideas and thus your wishes in the form of a unique web project that inspires you and
                        you customers.
                     </p>
                  </div>
               </div>
            </div>

            <div class="row">
               <div class="col-md-12">
                  {/* <div class="portfolio-filter text-center wow fadeInUp" data-wow-delay=".5s">
                     <div class="button-group filter-button-group">
                        <button data-filter="*" class="active">All</button>
                        <button data-filter=".uxui">UX/UI</button>
                        <button data-filter=".branding">Branding</button>
                        <button data-filter="">Apps</button>
                        <div class="active-bg"></div>
                     </div>
                  </div> */}

                  <div class="portfolio-box wow fadeInUp" data-wow-delay=".6s">
                     <div class="portfolio-sizer"></div>
                     <div class="gutter-sizer"></div>
                     <div class="portfolio-item branding">
                        <div class="image-box">
                        <iframe src="https://www.behance.net/embed/project/195807671?ilo0=1" height="500" width="100%" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>                        </div>
                        <div class="content-box">
                           <h3 class="portfolio-title">Post Design</h3>
                           <p>Design to Social Media Kahve Erbil</p>
                           </div>
                     </div>
                     <div class="portfolio-item branding">
                        <div class="image-box">
                        <iframe src="https://www.behance.net/embed/project/195775881?ilo0=1" height="500" width="100%" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
                        </div>
                        <div class="content-box">
                           <h3 class="portfolio-title">Post Design</h3>
                          
                           <p>Design to Social Media Dark LifeFit Erbil GYM</p>
                          </div>
                     </div>

                     <div class="portfolio-item uxui">
                        <div class="image-box">
                        <iframe src="https://www.behance.net/embed/project/134496271?ilo0=1" height="500" width="100%" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
                        </div>
                        <div class="content-box">
                           <h3 class="portfolio-title">Post Design</h3>
                          
                           <p>Design to Social Media Dark LifeFit Erbil GYM</p>
                          </div>
                     </div>
                     <div class="portfolio-item branding">
                        <div class="image-box">
                        <iframe src="https://www.behance.net/embed/project/195805481?ilo0=1" height="500" width="100%" allowfullscreen lazyload frameborder="0" allow="clipboard-write" refererPolicy="strict-origin-when-cross-origin"></iframe>
                        </div>
                        <div class="content-box">
                           <h3 class="portfolio-title">Post Design</h3>
                           <p>Design to Social Media Tablo Mall</p>
                          </div>
                     </div>
            
                  </div>
               </div>
            </div>
         </div>
      </section>
 
      <div id="portfolio-wrapper" class="popup_content_area zoom-anim-dialog mfp-hide">
         <div class="popup_modal_img">
            <img src="./assets/img/portfolio/modal-img.jpg" alt="" />
         </div>

         <div class="popup_modal_content">
            <div class="portfolio_info">
               <div class="portfolio_info_text">
                  <h2 class="title">DStudio</h2>
                  <div class="desc">
                     <p>
                        They are was greater open above shelter lets itself under appear sixth open gathering made upon
                        can't own above midst
                        gathering gathered he one us saying can't divide.
                     </p>
                  </div>
                  <a href="#" class="btn tj-btn-primary">live preview <i class="fal fa-arrow-right"></i></a>
               </div>
               <div class="portfolio_info_items">
                  <div class="info_item">
                     <div class="key">Category</div>
                     <div class="value">Web Design</div>
                  </div>
                  <div class="info_item">
                     <div class="key">Client</div>
                     <div class="value">Artboard Studio</div>
                  </div>
                  <div class="info_item">
                     <div class="key">Start Date</div>
                     <div class="value">August 20, 2023</div>
                  </div>
                  <div class="info_item">
                     <div class="key">Designer</div>
                     <div class="value"><a href="#">ThemeJunction</a></div>
                  </div>
               </div>
            </div>

            <div class="portfolio_gallery owl-carousel">
               <div class="gallery_item">
                  <img src="./assets/img/portfolio-gallery/p-gallery-1.jpg" alt="" />
               </div>
               <div class="gallery_item">
                  <img src="./assets/img/portfolio-gallery/p-gallery-2.jpg" alt="" />
               </div>
               <div class="gallery_item">
                  <img src="./assets/img/portfolio-gallery/p-gallery-3.jpg" alt="" />
               </div>
               <div class="gallery_item">
                  <img src="./assets/img/portfolio-gallery/p-gallery-4.jpg" alt="" />
               </div>
            </div>

            <div class="portfolio_description">
               <h2 class="title">Project Description</h2>
               <div class="desc">
                  <p>
                     The goal is there are many variations of passages of Lorem Ipsum available, but the majority have
                     suffered alteration in some
                     form, by injected humour, or randomised words which don't look even slightly believable.
                  </p>

                  <p>
                     There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                     alteration in some form, by
                     injected humour, or randomised words which don't look even slightly believable. If you are going to
                     use a passage of Lorem
                     Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                  </p>
               </div>
            </div>

            <div class="portfolio_story_approach">
               <div class="portfolio_story">
                  <div class="story_title">
                     <h4 class="title">The story</h4>
                  </div>
                  <div class="story_content">
                     <p>
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by
                        injected humour, or randomised words which don't look even slightly believable. If you are going
                        to use a passage of Lorem
                        Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. There
                        are many variations of
                        passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by
                        injected humour, or
                        randomised words which don't look even slightly believable. If you are going to use a passage of
                        Lorem Ipsum, you need to
                        be sure there isn't anything embarrassing hidden in the middle of text.
                     </p>
                  </div>
               </div>
               <div class="portfolio_approach">
                  <div class="approach_title">
                     <h4 class="title">OUR APPROACH</h4>
                  </div>
                  <div class="approach_content">
                     <p>
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by
                        injected humour, or randomised words which don't look even slightly believable. If you are going
                        to use a passage of Lorem
                        Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. There
                        are many variations of
                        passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by
                        injected humour, or
                        randomised words which don't look even slightly believable. If you are going to use a passage of
                        Lorem Ipsum, you need to
                        be sure there isn't anything embarrassing hidden in the middle of text.
                     </p>
                  </div>
               </div>
            </div>

            <div class="portfolio_navigation">
               <div class="navigation_item prev-project">
                  <a href="#" class="project">
                     <i class="fal fa-arrow-left"></i>
                     <div class="nav_project">
                        <div class="label">Previous Project</div>
                        <h3 class="title">Sebastian</h3>
                     </div>
                  </a>
               </div>

               <div class="navigation_item next-project">
                  <a href="#" class="project">
                     <div class="nav_project">
                        <div class="label">Next Project</div>
                        <h3 class="title">Qwillo</h3>
                     </div>
                     <i class="fal fa-arrow-right"></i>
                  </a>
               </div>
            </div>
         </div>
      </div>
    
      <section class="resume-section" id="resume-section">
         <div class="container">
            <div class="row">
               <div class="col-md-6">
                  <div class="section-header wow fadeInUp" data-wow-delay=".3s">
                     <h2 class="section-title"><i class="flaticon-recommendation"></i> My Experience</h2>
                  </div>

                  <div class="resume-widget">
                     <div class="resume-item wow fadeInLeft" data-wow-delay=".4s">
                        <div class="time">2020 - 2024</div>
                        <h3 class="resume-title">Web Design & Development</h3>
                        <div class="institute">Freelancer</div>
                     </div>
                     <div class="resume-item wow fadeInLeft" data-wow-delay=".5s">
                        <div class="time">2022 - 2023</div>
                        <h3 class="resume-title">Graphic Designer</h3>
                        <div class="institute">Tablo Mall</div>
                     </div>
                     <div class="resume-item wow fadeInLeft" data-wow-delay=".6s">
                        <div class="time">2021 - 2022</div>
                        <h3 class="resume-title">Programming</h3>
                        <div class="institute">Freelancer</div>
                     </div>
                     <div class="resume-item wow fadeInLeft" data-wow-delay=".7s">
                        <div class="time">2018 - 2020</div>
                        <h3 class="resume-title">IT Supporter </h3>
                        <div class="institute"> Enrocks Company in Dubai</div>
                     </div>
                  </div>
               </div>

               <div class="col-md-6">
                  <div class="section-header wow fadeInUp" data-wow-delay=".4s">
                     <h2 class="section-title"><i class="flaticon-graduation-cap"></i> My Education</h2>
                  </div>

                  <div class="resume-widget">
                     <div class="resume-item wow fadeInRight" data-wow-delay=".5s">
                        <div class="time">2016 - 2020</div>
                        <h3 class="resume-title">Bachelor in Computer Science</h3>
                        <div class="institute">University of Sulaymaniyah</div>
                     </div>
                     <div class="resume-item wow fadeInRight" data-wow-delay=".6s">
                        <div class="time">2022 - 2023</div>
                        <h3 class="resume-title">Real Online Business
Online</h3>
                        <div class="institute">Real Online business.</div>
                     </div>
                     <div class="resume-item wow fadeInRight" data-wow-delay=".7s">
                        <div class="time">2022 - 2023</div>
                        <h3 class="resume-title">Business Development</h3>
                        <div class="institute">Sulaymaniyah</div>
                     </div>
                    
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="skills-section" id="skills-section">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="section-header text-center">
                     <h2 class="section-title wow fadeInUp" data-wow-delay=".3s">My Skills</h2>
                     <p class="wow fadeInUp" data-wow-delay=".4s">
                        We put your ideas and thus your wishes in the form of a unique web project that inspires you and
                        you customers.
                     </p>
                  </div>
               </div>
            </div>

            <div class="row">
               <div class="col-md-12">
                  <div class="skills-widget d-flex flex-wrap justify-content-center align-items-center">
                     <div class="skill-item wow fadeInUp" data-wow-delay=".3s">
                        
                     <div class="skill-inner">
                        <p>Adobe photoshop</p>
                           <div class="number">60%</div>
                        </div>
                     </div>
                     <div class="skill-item wow fadeInUp" data-wow-delay=".4s">
                        <div class="skill-inner">
                        <p>Abobe illustrator</p>
                           <div class="number">70%</div>
                        </div>
                       
                     </div>
                     <div class="skill-item wow fadeInUp" data-wow-delay=".5s">
                        <div class="skill-inner">
                       
                        <p>React js</p>
                           <div class="number">60%</div>
                 
                     </div>
                     </div>
                     
                     <div class="skill-item wow fadeInUp" data-wow-delay=".7s">
                        <div class="skill-inner">
                        <p>Figma</p>
                           <div class="number">60%</div>
                          
                        </div>
                       
                     </div>
                     <div class="skill-item wow fadeInUp" data-wow-delay=".8s">
                     <div class="skill-inner">
                     <p>Mysql</p>
                           <div class="number">60%</div>
                         
                        </div>
                        </div>

                        <div class="skill-item wow fadeInUp" data-wow-delay=".8s">
                     <div class="skill-inner">
                     <p>js & css</p>
                           <div class="number">70%</div>
                         
                        </div>
                        </div>

                        <div class="skill-item wow fadeInUp" data-wow-delay=".8s">
                     <div class="skill-inner">
                     <p>Java </p>
                           <div class="number">75%</div>
                         
                        </div>
                        </div>

                        <div class="skill-item wow fadeInUp" data-wow-delay=".8s">
                     <div class="skill-inner">
                     <p>Php </p>
                           <div class="number">70%</div>
                         
                        </div>
                        </div>

                        <div class="skill-item wow fadeInUp" data-wow-delay=".8s">
                     <div class="skill-inner">
                     <p>Android Studio </p>
                           <div class="number">60%</div>
                         
                        </div>
                        </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

     
   

      <section class="contact-section" id="contact-section">
         <div class="container">
            <div class="row">
               <div class="col-lg-6 col-md-7 order-2 order-md-1">
                  <div class="contact-form-box wow fadeInLeft" data-wow-delay=".3s">
                     <div class="section-header">
                        <h2 class="section-title">Let’s work together!</h2>
                        <p>Design and code with a passion for creating beautifully simple solutions. I love what I do, and I strive for excellence in every project.</p>
                     </div>

                   
                  </div>
               </div>

               <div class="col-lg-5 offset-lg-1 col-md-5 d-flex flex-wrap align-items-center order-1 order-md-2">
                  <div class="contact-info-list">
                     <ul class="ul-reset">
                        <li class="d-flex flex-wrap align-items-center position-relative wow fadeInRight"
                           data-wow-delay=".4s">
                           <div class="icon-box">
                              <i class="flaticon-phone-call"></i>
                           </div>
                           <div class="text-box">
                              <p>Phone</p>
                              <a href="tel:0123456789">+964 783 115 84 34</a>
                           </div>
                        </li>
                        <li class="d-flex flex-wrap align-items-center position-relative wow fadeInRight"
                           data-wow-delay=".5s">
                           <div class="icon-box">
                              <i class="flaticon-mail-inbox-app"></i>
                           </div>
                           <div class="text-box">
                              <p>Email</p>
                              <a href="mailto:farmanosman225@mail.com">farmanosman225@mail.com</a>
                           </div>
                        </li>
                        <li class="d-flex flex-wrap align-items-center position-relative wow fadeInRight"
                           data-wow-delay=".6s">
                           <div class="icon-box">
                              <i class="flaticon-location"></i>
                           </div>
                           <div class="text-box">
                              <p>Address</p>
                              <a href="#">Kurdistan , Erbil</a>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <div class="modal contact_modal" id="message_sent" tabindex="-1">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <span class="modal-title"><strong>Message Sent Successfully</strong></span>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i
                        class="fas fa-times"></i></button>
               </div>
               <div class="modal-body">
                  <p>Thank you for contacting us. We will get back to you shortly.<br />Have a great day!</p>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
      </div>
  
      <div class="modal contact_modal failed" id="message_fail" tabindex="-1">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <span class="modal-title"><strong>Error</strong></span>
                  <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i
                        class="fas fa-times"></i></button>
               </div>
               <div class="modal-body">
                  <p>Oops! Something went wrong, please try again.</p>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
      </div>
   </main>


   <footer class="tj-footer-area">
      <div class="container">
         <div class="row">
            <div class="col-md-12 text-center">
               
               <div class="footer-menu">
                  <nav>
                     <ul>
                        <li><a href="#services-section">Services</a></li>
                        <li><a href="#works-section">Works</a></li>
                        <li><a href="#resume-section">Resume</a></li>
                        <li><a href="#skills-section">Skills</a></li>
                        <li><a href="#contact-section">Contact</a></li>
                     </ul>
                  </nav>
               </div>
               <div class="copy-text">
                  <p>&copy; 2025 All rights reserved by <a href="#" target="_blank">Farman Othman</a></p>
               </div>
            </div>
         </div>
      </div>
   </footer>
  
</body>

  );
}

export default App;
